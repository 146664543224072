<template>
    <v-card style="height: 100%; min-height: 350px;" class="chart-card">
        <DarkOverlayLoader v-if="ventasPorConvenio.isLoading" />
        <v-card-text class="chart-card-scroller">
            <div class="chart-card-content">
                <apexchart type="line" :options="options" :series="series"></apexchart>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
import { options } from '@coders-tm/vue-number-format';
import { DarkOverlayLoader } from '@/components/utils';
import { toMoneyFormat } from '@/utils/data';
import { mapActions, mapState } from 'vuex';

export default {
    name: 'TotalesConvenio',
    components: { DarkOverlayLoader },
    computed: {
        ...mapState('cmReporteria', ['ventasPorConvenio']),
        series() {
            return [
                {
                    name: 'Monto',
                    type: 'column',
                    data: this.ventasPorConvenio.data.map((convenio) => +convenio.total),
                }, 
                {
                    name: 'Cantidad de movimientos',
                    type: 'line',
                    data: this.ventasPorConvenio.data.map((convenio) => +convenio.cantidad_movimientos),
                },
            ];
        },
        options() {
            return ({
                chart: {
                    height: 350,
                    type: 'line',
                },
                stroke: {
                    width: [0, 4]
                },
                title: {
                    text: 'Movimientos por convenio'
                },
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1]
                },
                labels: this.ventasPorConvenio.data.map((convenio) => convenio.nombre),
                xaxis: {
                    labels: {
                        show: false,
                    },
                },
                yaxis: [
                    {
                        title: {
                            text: 'Monto',
                        },
                        labels: {
                            formatter: function (value) {
                                return toMoneyFormat(value);
                            },
                        },
                    }, 
                    {
                        opposite: true,
                        title: {
                            text: 'Cantidad de movimientos'
                        },
                        labels: {
                            formatter: function (value) {
                                return Number(value).toFixed(0);
                            },
                        },
                    }
                ]
          })
        },
    },
    methods: {
        ...mapActions('cmReporteria', ['cargarVentasPorConvenio']),
    },
    created() {
        this.cargarVentasPorConvenio();
    },
}
</script>
<style>
@media (max-width: 1264px) {
    .chart-card {
        max-width: 100%;
        overflow: hidden !important;
    }

    .chart-card-scroller {
        overflow: auto;
        max-width: 100%;
    }
    
    .chart-card-content {
        min-width: 1000px;
    }
}
</style>