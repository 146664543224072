<template>
    <v-card style="height: 100%; min-height: 350px;" class="chart-card">
        <DarkOverlayLoader v-if="topProductos.isLoading" />
        <v-card-text class="chart-card-scroller">
            <div class="chart-card-content">
                <apexchart
                    width="100%" 
                    type="bar" 
                    :options="options" 
                    :series="series"
                />
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
import { DarkOverlayLoader } from '@/components/utils';
import { toMoneyFormat } from '@/utils/data';
import { mapState, mapActions } from 'vuex';

export default {
    name: 'TopProductos',
    components: { DarkOverlayLoader },
    computed: {
        ...mapState("cmReporteria", ['topProductos']),
        series() {
            const data = this.topProductos.data.map((producto) => ({
                x: producto.nombre,
                y: producto.total,
            }));

            return [
                {
                    name: "Monto",
                    data,
                }
            ]
        },
        options() {
            return {
                chart: {
                    type: 'bar',
                    height: 380
                },
                xaxis: {
                    type: 'category',
                    labels: {
                        formatter: function() {
                            return ""
                        },
                    },
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return toMoneyFormat(value);
                        },
                    },
                },
                title: {
                    text: 'Productos más vendidos',
                },
                tooltip: {
                    x: {
                        formatter: function(val) {
                            return val;
                        }  
                    }
                },
                dataLabels: {
                    enabled: false
                },
            }
        },
    },
    methods: {
        ...mapActions('cmReporteria', ['cargarTopProductos']),
    },
    created() {
        this.cargarTopProductos();
    },
}
</script>
<style>
@media (max-width: 1264px) {
    .chart-card {
        max-width: 100%;
        overflow: hidden !important;
    }

    .chart-card-scroller {
        overflow: auto;
        max-width: 100%;
    }
    
    .chart-card-content {
        min-width: 1000px;
    }
}
</style>