<template>
  <v-card style="height: 100%">
    <v-card-title>
      <p class="pb-0 mb-0 font-weight-bold text-uppercase subtitle-1" style="word-break: break-word;">
        <v-icon class="mr-1" style="vertical-align: middle;">mdi-cash-multiple</v-icon>
        Total compras
      </p>
    </v-card-title>
    <v-divider/>
    <v-card-text>
      <div>
        <span class="text-caption text-capitalize">total</span>
        <br/>
        <span class="text-h5 font-weight-bold">{{ toMoneyFormat(estadisticas.data?.sumaSubtotales) }}</span>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>

export default {
    name: 'Estadisticas',
    props: {
      estadisticas: { type: Object }
    },
    computed: {
      cargando() {
        return this.estadisticas.isLoading;
      },
    },
    methods: {
        toMoneyFormat(value) {
            if (this.cargando) return 'Cargando...';

            if (!value || Number.isNaN(value)) {
                return '$0.00';
            }

            return Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(value);
        },
    },
}
</script>