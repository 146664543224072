<template>
    <v-dialog v-model="isOpen" max-width="800px" persistent>
        <v-card style="position: relative;">
            <v-card-title class="px-0 pt-4 pb-0 d-block" style="position: sticky; top: 0px; background: white; z-index: 100;">
                <div class="d-flex align-center px-4 pb-4" style="gap: 8px;">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="pt-0"
                        @click.stop="cerrarModal" 
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                    <h5 class="text-h6 secondary--text text-uppercase font-weight-bold">
                        Detalles movimiento
                    </h5>
                </div>
                <v-divider />
            </v-card-title>
            <v-card-text class="px-4 pt-4 pb-0">
                <div class="d-flex align-center justify-space-between pb-2 " style="gap: 8px;">
                    <h5 class="text-subtitle-2 text-uppercase font-weight-bold">
                        <v-icon style="font-size: 22px;" class="mr-1">mdi-invoice-check</v-icon>
                        Detalle del movimiento
                    </h5>
                    <v-chip
                        v-if="movimiento"
                        label
                        small
                        class="text-uppercase font-weight-bold"
                        :color="obtenerColorFondoEstado(movimiento.secuencia)"
                        :text-color="obtenerColorTextEstado(movimiento.secuencia)"
                    >
                        {{ movimiento.estado.nombre }}
                    </v-chip>
                </div>
                <v-divider />
                <div v-if="movimiento" class="d-flex flex-column flex-md-row grupo-detalles" style="gap: 4px;">
                    <dl class="flex-grow-1 flex-shrink-1" style="flex-basis: 50%;">
                        <dt class="font-weight-bold text-body-2 mt-3">Tipo de convenio:</dt>
                        <dd class="text-body-2">{{ movimiento.producto.tipo_producto.nombre }}</dd>
    
                        <dt class="font-weight-bold text-body-2 mt-3">Nombre de convenio:</dt>
                        <dd class="text-body-2">{{ movimiento.familia.nombre }}</dd>
    
                        <dt class="font-weight-bold text-body-2 mt-3">Producto:</dt>
                        <dd class="text-body-2">{{ movimiento.producto.nombre }}</dd>
                    </dl>
                    <dl class="flex-grow-1 flex-shrink-1" style="flex-basis: 50%;">
                        <dt class="font-weight-bold text-body-2 mt-3">Proveedor:</dt>
                        <dd class="text-body-2">{{ movimiento.Proveedor.nombre_comercial }}</dd>

                        <dt class="font-weight-bold text-body-2 mt-3">Fecha de compra:</dt>
                        <dd class="text-body-2">{{ formatDate(movimiento.fecha_compra) }}</dd>
    
                        <dt class="font-weight-bold text-body-2 mt-3">Monto:</dt>
                        <dd class="text-body-2">
                            ${{ 
                                Intl.NumberFormat("en-US", {
                                    currency: "USD",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 8,
                                }).format(movimiento.monto ?? movimiento.subtotal) 
                            }}
                        </dd>
                    </dl>
                </div>

                <!-- Detalle de solicitud -->
                <h5 class="text-subtitle-2 text-uppercase font-weight-bold mt-8 mb-2">
                    <v-icon style="font-size: 22px;" class="mr-1">mdi-invoice-list-outline</v-icon>
                    Detalle de la solicitud
                </h5>
                <v-divider />
                <div v-if="movimiento" class="d-flex flex-column flex-md-row grupo-detalles">
                    <dl class="flex-grow-1 flex-shrink-1" style="flex-basis: 50%;">
                        <dt class="font-weight-bold text-body-2 mt-3">Institución:</dt>
                        <dd class="text-body-2">{{ movimiento.institucion.nombre }}</dd>

                        <dt class="font-weight-bold text-body-2 mt-3">Código del proceso:</dt>
                        <dd class="text-body-2">{{ movimiento.solicitud.proceso_compra.codigo_proceso  }}</dd>
    
                        <dt class="font-weight-bold text-body-2 mt-3">Nombre del proceso:</dt>
                        <dd class="text-body-2">{{ movimiento.solicitud.proceso_compra.nombre_proceso }}</dd>
                    </dl>

                    <dl class="flex-grow-1 flex-shrink-1" style="flex-basis: 50%;">
                        <dt class="font-weight-bold text-body-2 mt-3">Fecha de autorización:</dt>
                        <dd class="text-body-2">{{ formatDate(movimiento.solicitud.fecha_autorizacion) }}</dd>
                        
                        <dt class="font-weight-bold text-body-2 mt-3">Correo administrador contractual:</dt>
                        <dd class="text-body-2">{{ movimiento.solicitud.administrador_contractual?.usuario?.email ?? '-' }}</dd>
    
                        <dt class="font-weight-bold text-body-2 mt-3">Nombre administrador contractual:</dt>
                        <dd class="text-body-2">{{ movimiento.solicitud.administrador_contractual?.nombre_completo ?? '-' }}</dd> 
                    </dl>
                </div>

                <h5 class="text-subtitle-2 text-uppercase font-weight-bold mt-8 mb-2">
                    <v-icon style="font-size: 22px;" class="mr-1">mdi-home-search</v-icon>
                    Detalles internos
                </h5>
                <v-divider />
                <div v-if="movimiento" class="d-flex flex-column flex-md-row grupo-detalles">
                    <dl class="flex-grow-1 flex-shrink-1" style="flex-basis: 50%;">
                        <dt class="font-weight-bold text-body-2 mt-3">ID de solicitud:</dt>
                        <dd class="text-body-2">{{ movimiento.solicitud.id }}</dd>

                        <dt class="font-weight-bold text-body-2 mt-3">ID de producto:</dt>
                        <dd class="text-body-2">{{ movimiento.id }}</dd>
                    </dl>
                    <dl class="flex-grow-1 flex-shrink-1" style="flex-basis: 50%;">
                        <dt class="font-weight-bold text-body-2 mt-3">ID de movimiento:</dt>
                        <dd class="text-body-2">{{ movimiento.movimiento.id ?? '-' }}</dd>
                    </dl>
                </div>

                <div class="d-flex justify-center align-center mt-4 py-4" style="position: sticky; bottom: 0; background: white;">
                    <v-btn color="primary" @click.stop="cerrarModal">Cerrar</v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import { formatDate } from '@/utils/datetime';

export default {
    name: 'ModalDetallesMovimientoCm',
    props: {
        isOpen: { type: Boolean },
        movimiento: { type: Object },
    },
    data: () => ({
    }),
    computed: {
    },
    methods: {
        formatDate,
        cerrarModal() {
            this.$emit('update:is-open', false);
        },
        obtenerColorFondoEstado(secuencia) {
            if (secuencia?.finaliza_exitosamente) return '#00B6F5';
            if (secuencia?.retroceso_en_flujo) return '#252B58';
        },
        obtenerColorTextEstado(secuencia) {
            if (secuencia?.finaliza_exitosamente || secuencia?.retroceso_en_flujo) return '#FFF !important';
        },
    },
}
</script>
<style scoped>
@media (min-width: 960px) {
    .grupo-detalles {
        gap: 24px;
    }
}
</style>